import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { EmojiPicker } from '@ui/EmojiPicker';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';
import { insertText } from '@utils/documentExecCommand';
import { usePlanLimitReached } from '@utils/Data/Pricing';
import {
  GetPlanLimitReachedTextPath,
  PlanLimitReachedCallout,
} from '@components/PlanLimitReachedCallout';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextArea from 'react-textarea-autosize';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Folder, Platform, TierType } from '@globals';
import { useLiveChatMessagesUploadingContext } from '../../../hooks/LiveChatMessagesUploadingContext/LiveChatMessagesUploadingContext';
import { MessageAttachment } from '../../../hooks/LiveChatMessagesUploadingContext/types';
import { sendMessageProps } from '../../SendMessage';
import { HeartSticker, SenderAvatar, SendFlowButton } from './InputControls';
import { MessageInputAttachment } from './MessageInputAttachment';
import * as css from './MessageInput.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { MessengerWindowWarning, WhatsappWindowWarning } from './WindowWarning';
import { DateFormat } from '@utils/DateTime';
import nanoid from 'nanoid';
import { AttachmentSelector } from './components/AttachmentSelector';
import { FileAttachmentType } from '@utils/UploadService/types';
import { ExecutionResult } from 'graphql';
import { useCurrentBotId } from '@utils/Routing';
import { SendLivechatMessage } from '../../SendMessage/@types/SendLivechatMessage';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import { useMessageInputDefaultValue } from './hooks/useMessageInputDefaultValue';
import { CopilotMenu } from './components/CopilotMenu/CopilotMenu';
import { useCopilot } from './hooks/useCopilot';
import { useCopilotPromoBanner } from './components/CopilotPromoBanner/useCopilotPromoBanner';
import { CopilotPromoBanner } from './components/CopilotPromoBanner/CopilotPromoBanner';
import { useToaster } from '@ui/Toaster';

function some(
  collection: HTMLFormControlsCollection,
  predicate: (control: Element) => boolean,
) {
  // tslint:disable-next-line no-increment-decrement
  for (let i = 0; i < collection.length; i++) {
    if (predicate(collection[i])) {
      return true;
    }
  }
  return false;
}

const ATTACHMENT_ANIMATION_DURATION = 300;

const getPlanLimitReachedTextPath: GetPlanLimitReachedTextPath = (
  dialogPricingEnabled,
  currentTier,
) => {
  if (!dialogPricingEnabled)
    return currentTier !== TierType.trial
      ? {
          title: 'pages.LiveChat.planLimitReached.subscribersPayment.title',
          button: 'pages.LiveChat.planLimitReached.subscribersPayment.button',
        }
      : {
          title: 'pages.LiveChat.planLimitReached.subscribers.title',
          button: 'pages.LiveChat.planLimitReached.subscribers.button',
        };
  return {
    title: 'pages.LiveChat.planLimitReached.dialogues.title',
    button: 'pages.LiveChat.planLimitReached.dialogues.button',
  };
};

interface MessageInputProps {
  onSubmit: (
    message: sendMessageProps,
  ) => Promise<ExecutionResult<SendLivechatMessage>>;
  disableSendButton?: boolean;
  showOut7DaysWindowWarning?: boolean;
  disabled?: boolean;
  placeholder?: string;
  liveChatIsOn?: boolean;
  conversationFolder: Folder;
  platform: Platform;
  dialogListPlatform: Platform | null;
  conversationId: string;
  showOut24HoursWindowWarning: boolean;
  showNotOpen24HoursWindowWarning: boolean;
}

const AudioButton: React.FC<
  Pick<MessageInputProps, 'platform'> & {
    disabled: boolean;
    onClick: VoidFunction;
    isRecordingAttachment: boolean;
  }
> = ({ platform, disabled, onClick, isRecordingAttachment }) => {
  const { isAudioAvailable, isLiveChatWebView } = useIsLiveChatWebView();

  if (platform !== Platform.whatsapp) return null;
  if (isLiveChatWebView && !isAudioAvailable) return null;

  return (
    <Button
      data-testid="livechat_message-input_attachment-audio"
      intent="text"
      size="s"
      disabled={disabled || isRecordingAttachment}
      onClick={onClick}
      icon={
        <Icon
          icon="microphone"
          color={isRecordingAttachment ? 'blue' : 'black'}
        />
      }
    />
  );
};

export const MessageInput: React.FC<MessageInputProps> = ({
  onSubmit,
  placeholder = window.i18next.t('MessageInput-string-7504-write-a-message'),
  disableSendButton,
  showOut7DaysWindowWarning,
  showOut24HoursWindowWarning,
  showNotOpen24HoursWindowWarning,
  disabled: baseDisabled,
  liveChatIsOn,
  conversationFolder,
  platform,
  dialogListPlatform,
  conversationId,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const [isValid, setIsValid] = useState(false);
  const [isRecordingAttachment, setIsRecordingAttachment] = useState(false);
  const [attachments, setAttachments] = useState<MessageAttachment[]>([]);
  const [showAttachmentSection, setShowAttachmentSection] = useState(false);
  const planLimitReached = usePlanLimitReached();
  const botId = useCurrentBotId();
  const [paraphrase, { loading }] = useCopilot();
  const formRef = useRef<HTMLFormElement>(null);
  const disabled = baseDisabled || Boolean(planLimitReached?.visible);
  const { addToaster } = useToaster();
  const { isHidden: isCopilotPromoHidden, hide: hideCopilotPromo } =
    useCopilotPromoBanner();
  const addAttachment = useCallback((file: MessageAttachment) => {
    setAttachments((currentAttachments) => {
      if (currentAttachments.some((item) => item.name === file.name)) {
        return currentAttachments.map((item) =>
          item.name === file.name ? file : item,
        );
      }
      return [...currentAttachments, file];
    });
  }, []);

  const removeAttachment = useCallback((file: MessageAttachment) => {
    if (file.type === FileAttachmentType.audio && file.active) {
      setIsRecordingAttachment(false);
    }
    setAttachments((currentAttachments) => {
      return currentAttachments.filter((item) => item.name !== file.name);
    });
  }, []);

  const updateAttachment = useCallback(
    (updatedAttachment: MessageAttachment, index: number) => {
      setAttachments((currentAttachments) => {
        currentAttachments.splice(index, 1, updatedAttachment);
        return currentAttachments;
      });
      if (!updatedAttachment.active) {
        setIsRecordingAttachment(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (attachments.length > 0) {
      setShowAttachmentSection(true);
    } else {
      setTimeout(
        () => setShowAttachmentSection(false),
        ATTACHMENT_ANIMATION_DURATION,
      );
    }
  }, [attachments]);

  const [defaultValue, setDefaultValue] =
    useMessageInputDefaultValue(conversationId);

  const { sendMessages: startSendAttachment } =
    useLiveChatMessagesUploadingContext();

  const handleSubmit = async (form: HTMLFormElement) => {
    if (isRecordingAttachment) {
      return;
    }
    setAttachments([]);
    if (isValid) {
      const { value } = form.elements.namedItem(
        'livechatInput',
      ) as HTMLInputElement;
      form.reset();
      setDefaultValue('');
      setIsValid(false);
      if (attachments.length > 0) {
        await onSubmit({ text: value });
      } else {
        onSubmit({ text: value });
      }
    }
    if (attachments.length > 0) {
      sendEvent({
        category: 'live chat',
        action: 'send attachments',
        propertyBag: {
          conversationId,
          platform,
          attachmentNumber: attachments.length,
        },
      });
      startSendAttachment(
        attachments.map((attachment) => ({
          id: nanoid(),
          conversationId,
          platform,
          attachment,
        })),
      );
    }
  };

  const sendButtonDisabled =
    disableSendButton ||
    (liveChatIsOn && !isValid && attachments.length === 0) ||
    disabled ||
    isRecordingAttachment;

  const getPlanLimitReachedCallout = (className?: string) =>
    planLimitReached ? (
      <PlanLimitReachedCallout
        {...planLimitReached}
        iconDisabled
        sourcePage="live chat"
        getTextPath={getPlanLimitReachedTextPath}
        className={className}
      />
    ) : null;

  const getWarning = (className?: string) =>
    ({
      [Platform.facebook]: showOut7DaysWindowWarning ? (
        <MessengerWindowWarning className={className} />
      ) : null,
      [Platform.instagram]: showOut7DaysWindowWarning ? (
        <MessengerWindowWarning className={className} />
      ) : null,
      [Platform.whatsapp]:
        showOut24HoursWindowWarning || showNotOpen24HoursWindowWarning ? (
          <WhatsappWindowWarning
            className={className}
            is24WindowNotOpen={showNotOpen24HoursWindowWarning}
          />
        ) : null,
    }[platform]);

  if (isSmallScreenSize && planLimitReached?.visible) {
    return getPlanLimitReachedCallout(css.mobileWarningMessage);
  }

  if (isSmallScreenSize && getWarning()) {
    return getWarning(css.mobileWarningMessage);
  }

  if (isSmallScreenSize && !isCopilotPromoHidden) {
    return (
      <CopilotPromoBanner
        className={css.mobileWarningMessage}
        onHide={() => {
          hideCopilotPromo();
        }}
      />
    );
  }

  return (
    <form
      ref={formRef}
      className={cn(css.root, isSmallScreenSize && css.root_mobile)}
      onChange={({ currentTarget: form }) => {
        const validity =
          form.checkValidity() &&
          (
            form.elements.namedItem('livechatInput') as HTMLInputElement
          )?.value.trim().length > 0;
        if (validity !== isValid) {
          setIsValid(validity);
        }
      }}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(event.currentTarget);
      }}
      onKeyDown={(event) => {
        const form = event.currentTarget;
        const { target } = event;
        if (event.key === 'Enter') {
          if (event.metaKey || event.ctrlKey || event.shiftKey) {
            // allow line break
            return;
          }
          const targetIsFormControl = some(
            form.elements,
            (control) => control === target,
          );
          if (targetIsFormControl) {
            event.preventDefault(); // do not type newline
            handleSubmit(form);
          }
        }
      }}
      noValidate
    >
      <EmojiPicker
        buttonSpaceWidth={0}
        externalEmojiIcon
        onSelect={(emoji, el) => {
          insertText(emoji.native, el);
        }}
        disabled={disabled}
      >
        {({ bind: { ref, ...bind }, isOpen, emojiIconProps }) => (
          <>
            <div
              className={cn(
                css.inputContent,
                isSmallScreenSize && css.inputContent_mobile,
                isSmallScreenSize &&
                  showAttachmentSection &&
                  css.inputContent_mobileAttachments,
              )}
            >
              {showAttachmentSection && (
                <TransitionGroup className={css.attachmentsContainer}>
                  {attachments.map((attachment, index) => (
                    <CSSTransition
                      key={attachment.name}
                      timeout={ATTACHMENT_ANIMATION_DURATION}
                      classNames={{
                        exit: css.attachmentExit,
                        exitActive: css.attachmentSendActive,
                      }}
                    >
                      <MessageInputAttachment
                        attachment={attachment}
                        onRemove={() => removeAttachment(attachment)}
                        onChange={(updatedAttachment) => {
                          updateAttachment(updatedAttachment, index);
                        }}
                      />
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              )}
              {planLimitReached?.visible ? (
                getPlanLimitReachedCallout()
              ) : (
                <>
                  {getWarning()}
                  {!isCopilotPromoHidden && !getWarning() && (
                    <CopilotPromoBanner
                      className={css.copilotCallout}
                      onHide={() => {
                        hideCopilotPromo();
                      }}
                    />
                  )}
                  <TextArea
                    className={cn(css.textarea, {
                      [css.blinking]: loading,
                    })}
                    name="livechatInput"
                    minRows={showAttachmentSection || isSmallScreenSize ? 2 : 4}
                    maxRows={showAttachmentSection ? 2 : 4}
                    defaultValue={defaultValue}
                    required
                    placeholder={placeholder}
                    disabled={disabled}
                    data-testid="livechat_message-input_textarea"
                    inputRef={(el) => {
                      // eslint-disable-next-line no-param-reassign
                      ref.current = el;
                    }}
                    style={{ padding: 0 }}
                    {...bind}
                  />
                </>
              )}
            </div>
            <Flex justifyContent="space-between" alignItems="flex-end">
              <Flex alignItems="center">
                <SenderAvatar platform={platform} disabled={disabled} />
                <SendFlowButton
                  platform={platform}
                  dialogListPlatform={dialogListPlatform}
                  conversationId={conversationId}
                  conversationFolder={conversationFolder}
                  disabled={platform === Platform.facebook ? false : disabled}
                />
                <Spacer horizontalFactor={2} />
                <AttachmentSelector
                  disabled={disabled}
                  onAttachmentChoose={addAttachment}
                  conversationId={conversationId}
                  platform={platform}
                />
                <Spacer horizontalFactor={2} />
                {!isSmallScreenSize && (
                  <>
                    <Button
                      {...emojiIconProps}
                      intent="text"
                      size="s"
                      disabled={disabled}
                      icon={
                        <Icon icon="emoji" color={isOpen ? 'blue' : 'black'} />
                      }
                    />
                    <Spacer horizontalFactor={2} />
                  </>
                )}
                {platform === Platform.instagram && (
                  <HeartSticker onSubmit={onSubmit} disabled={disabled} />
                )}
                <AudioButton
                  platform={platform}
                  disabled={disabled}
                  isRecordingAttachment={isRecordingAttachment}
                  onClick={() => {
                    addAttachment({
                      name: DateFormat.DDMMMMYYYYHHMMSS(Date.now()),
                      type: FileAttachmentType.audio,
                      active: true,
                    });
                    setIsRecordingAttachment(true);
                  }}
                />
                <CopilotMenu
                  onItemSelect={({ id: mode }) => {
                    const inputElement =
                      // @ts-ignore
                      formRef.current?.elements.livechatInput;

                    const inputValue = inputElement?.value;

                    if (inputValue) {
                      paraphrase({
                        variables: {
                          mode,
                          text: inputValue,
                          botId: botId!,
                        },
                      }).then((res) => {
                        sendEvent({
                          category: 'live chat',
                          action: 'apply copilot',
                          propertyBag: {
                            mode,
                          },
                        });
                        addToaster({
                          type: 'info',
                          content: t('Copilot.toaster.message'),
                          closeByClick: true,
                          buttonText: t('Copilot.toaster.revert'),
                          onButtonClick: () => {
                            sendEvent({
                              category: 'live chat',
                              action: 'revert copilot changes',
                              propertyBag: {
                                mode,
                              },
                            });
                            inputElement.value = inputValue;
                          },
                          timeout: 5000,
                        });
                        const refactor = res.data?.paraphraseForBot?.text;
                        if (refactor) {
                          inputElement.value = refactor;
                        }
                      });
                    }
                  }}
                />
              </Flex>
              <Button
                size={isSmallScreenSize ? 's' : undefined}
                type="submit"
                intent={isSmallScreenSize ? 'primary' : 'text'}
                data-testid="livechat_message-send-button"
                disabled={sendButtonDisabled}
                onClick={() => ref.current?.focus()}
              >
                {t('MessageInput-JSXText-1056-send')}
              </Button>
            </Flex>
          </>
        )}
      </EmojiPicker>
    </form>
  );
};
