import React from 'react';
import { SimpleCombobox, SimpleComboboxProps } from '@ui/SimpleCombobox';
import { Icon } from '@ui/Icon';
import { MenuItem } from '@ui/Menu';
import { useCurrentBotId } from '@utils/Routing';
import { Avatar } from '../Avatar';
import {
  useLiveChatAdmins,
  LivechatAdmin,
} from '../../hooks/useLiveChatAdmins';
import { UNASSIGNED_ITEM } from './constants';
import css from './AssigneesMenu.css';

interface AssigneesMenuProps {
  children(props: any): JSX.Element;
  title?: string;
  assigneeId?: string;
  unassignedByDefault?: boolean;
  onAssigneeChange?(admin?: LivechatAdmin): void;
  comboboxProps?: Partial<SimpleComboboxProps<LivechatAdmin>>;
}

const TITLE_ITEM = {
  id: '_title',
  type: 'title',
};

export const AssigneesMenu: React.FC<AssigneesMenuProps> = ({
  title,
  assigneeId,
  onAssigneeChange,
  unassignedByDefault,
  children,
  comboboxProps,
}) => {
  const botId = useCurrentBotId();
  const { admins } = useLiveChatAdmins(botId!);

  const items: LivechatAdmin[] = [UNASSIGNED_ITEM as any].concat(admins);
  if (title) {
    items.unshift({ name: title, ...TITLE_ITEM } as any);
  }

  return (
    <SimpleCombobox<LivechatAdmin & { type?: string }>
      {...comboboxProps}
      items={items}
      position="bottom-start"
      renderInput={children}
      renderItem={({
        getItemProps,
        item,
        index,
        highlightedIndex,
        closeMenu,
      }) => {
        const active = index === highlightedIndex;
        const currentItemIsUnassigned = item.id === UNASSIGNED_ITEM.id;
        const unassignedItemActive =
          unassignedByDefault && !assigneeId && currentItemIsUnassigned;
        const currentItemSelected =
          unassignedItemActive || item.id === assigneeId;

        const commonProps = {
          ...getItemProps({ item }),
          key: `${item.id}_${index}`,
          type: item.type,
          active,
          title: item.name,
          className: css.menuItem,
          onClick: (event: Event) => {
            event.preventDefault();
            if (item?.name && !item.type) {
              if (!currentItemSelected) {
                if (currentItemIsUnassigned) {
                  onAssigneeChange?.();
                } else {
                  onAssigneeChange?.(item);
                }
              }
              closeMenu();
            }
          },
        };

        switch (item.type) {
          case 'title':
            return <MenuItem {...commonProps} />;

          default:
            return (
              <MenuItem
                {...commonProps}
                leftElement={
                  currentItemIsUnassigned ? (
                    <Icon icon="assign" color={active ? 'white' : 'black'} />
                  ) : (
                    <Avatar src={item.picture} size={24} />
                  )
                }
                rightElement={
                  currentItemSelected && (
                    <Icon icon="check" color={active ? 'white' : 'blue'} />
                  )
                }
              />
            );
        }
      }}
      unsaveSelectedOption
    />
  );
};
